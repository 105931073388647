import { default as affiliateDtKtcTxbaqMeta } from "/app/src/layers/pages_and_theme/pages/affiliate.vue?macro=true";
import { default as api_45documentationNRHIWmMHhDMeta } from "/app/src/layers/pages_and_theme/pages/api-documentation.vue?macro=true";
import { default as index5uuPYGbSWLMeta } from "/app/src/layers/blog/pages/blog/[...slug]/index.vue?macro=true";
import { default as indexX91Yjb457eMeta } from "/app/src/layers/blog/pages/blog/[...slug]/page[number]/index.vue?macro=true";
import { default as indexdyZwm2KoEgMeta } from "/app/src/layers/blog/pages/blog/index.vue?macro=true";
import { default as indexDG6flrtQwxMeta } from "/app/src/layers/blog/pages/blog/page[number]/index.vue?macro=true";
import { default as indexZyuPCez693Meta } from "/app/src/layers/blog/pages/docs/[slug]/index.vue?macro=true";
import { default as indexVJX8RoFQMYMeta } from "/app/src/layers/blog/pages/docs/index.vue?macro=true";
import { default as index76wWvRgv1VMeta } from "/app/src/layers/pages_and_theme/pages/exchange-[pair]/index.vue?macro=true";
import { default as how_45it_45worksOZ541I1qaYMeta } from "/app/src/layers/pages_and_theme/pages/how-it-works.vue?macro=true";
import { default as indexVtwkjil2EGMeta } from "/app/src/layers/pages_and_theme/pages/index.vue?macro=true";
import { default as lkgYBEF3tI3WMeta } from "/app/src/layers/exchanger/pages/lk.vue?macro=true";
import { default as _91id_93tQI02dp3nnMeta } from "/app/src/layers/exchanger/pages/order/[id].vue?macro=true";
import { default as NotCorrectDestinationAddressFormAIAVlxYTq4Meta } from "/app/src/layers/exchanger/pages/order/NotCorrectDestinationAddressForm.vue?macro=true";
import { default as our_45visioni48l4QvUjPMeta } from "/app/src/layers/pages_and_theme/pages/our-vision.vue?macro=true";
import { default as indexV9Y4eKLUW7Meta } from "/app/src/layers/exchanger/pages/popular-exchanges/index.vue?macro=true";
import { default as affiliate_45programKrH9eG0vZ1Meta } from "/app/src/layers/exchanger/pages/profile/affiliate-program.vue?macro=true";
import { default as infoVkhXKCfC7CMeta } from "/app/src/layers/exchanger/pages/profile/info.vue?macro=true";
import { default as keys1qS3yXmXdaMeta } from "/app/src/layers/exchanger/pages/profile/keys.vue?macro=true";
import { default as walletj4Sj4zIMFUMeta } from "/app/src/layers/exchanger/pages/profile/wallet.vue?macro=true";
import { default as withdraw5GEox3h2EvMeta } from "/app/src/layers/exchanger/pages/profile/withdraw.vue?macro=true";
import { default as roadmapDhWp8DkIOsMeta } from "/app/src/layers/blog/pages/roadmap.vue?macro=true";
import { default as supportLhBSJgUR3TMeta } from "/app/src/layers/pages_and_theme/pages/support.vue?macro=true";
import { default as testpage2LB4q27ZA25Meta } from "/app/src/layers/pages_and_theme/pages/testpage2.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "affiliate___en",
    path: "/affiliate",
    component: () => import("/app/src/layers/pages_and_theme/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: "affiliate___ru",
    path: "/ru/affiliate",
    component: () => import("/app/src/layers/pages_and_theme/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: "api-documentation___en",
    path: "/api-documentation",
    component: () => import("/app/src/layers/pages_and_theme/pages/api-documentation.vue").then(m => m.default || m)
  },
  {
    name: "api-documentation___ru",
    path: "/ru/api-documentation",
    component: () => import("/app/src/layers/pages_and_theme/pages/api-documentation.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug(.*)*",
    meta: index5uuPYGbSWLMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___ru",
    path: "/ru/blog/:slug(.*)*",
    meta: index5uuPYGbSWLMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug-pagenumber___en",
    path: "/blog/:slug(.*)*/page:number()",
    meta: indexX91Yjb457eMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/[...slug]/page[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug-pagenumber___ru",
    path: "/ru/blog/:slug(.*)*/page:number()",
    meta: indexX91Yjb457eMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/[...slug]/page[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexdyZwm2KoEgMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    meta: indexdyZwm2KoEgMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-pagenumber___en",
    path: "/blog/page:number()",
    meta: indexDG6flrtQwxMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/page[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-pagenumber___ru",
    path: "/ru/blog/page:number()",
    meta: indexDG6flrtQwxMeta || {},
    component: () => import("/app/src/layers/blog/pages/blog/page[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-slug___en",
    path: "/docs/:slug()",
    meta: indexZyuPCez693Meta || {},
    component: () => import("/app/src/layers/blog/pages/docs/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-slug___ru",
    path: "/ru/docs/:slug()",
    meta: indexZyuPCez693Meta || {},
    component: () => import("/app/src/layers/blog/pages/docs/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___en",
    path: "/docs",
    meta: indexVJX8RoFQMYMeta || {},
    component: () => import("/app/src/layers/blog/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs___ru",
    path: "/ru/docs",
    meta: indexVJX8RoFQMYMeta || {},
    component: () => import("/app/src/layers/blog/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-pair___en",
    path: "/exchange-:pair()",
    meta: index76wWvRgv1VMeta || {},
    component: () => import("/app/src/layers/pages_and_theme/pages/exchange-[pair]/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-pair___ru",
    path: "/ru/exchange-:pair()",
    meta: index76wWvRgv1VMeta || {},
    component: () => import("/app/src/layers/pages_and_theme/pages/exchange-[pair]/index.vue").then(m => m.default || m)
  },
  {
    name: "how-it-works___en",
    path: "/how-it-works",
    component: () => import("/app/src/layers/pages_and_theme/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: "how-it-works___ru",
    path: "/ru/how-it-works",
    component: () => import("/app/src/layers/pages_and_theme/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexVtwkjil2EGMeta || {},
    component: () => import("/app/src/layers/pages_and_theme/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexVtwkjil2EGMeta || {},
    component: () => import("/app/src/layers/pages_and_theme/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lk___en",
    path: "/lk",
    meta: lkgYBEF3tI3WMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/lk.vue").then(m => m.default || m)
  },
  {
    name: "lk___ru",
    path: "/ru/lk",
    meta: lkgYBEF3tI3WMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/lk.vue").then(m => m.default || m)
  },
  {
    name: "order-id___en",
    path: "/order/:id()",
    component: () => import("/app/src/layers/exchanger/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "order-id___ru",
    path: "/ru/order/:id()",
    component: () => import("/app/src/layers/exchanger/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "order-NotCorrectDestinationAddressForm___en",
    path: "/order/NotCorrectDestinationAddressForm",
    component: () => import("/app/src/layers/exchanger/pages/order/NotCorrectDestinationAddressForm.vue").then(m => m.default || m)
  },
  {
    name: "order-NotCorrectDestinationAddressForm___ru",
    path: "/ru/order/NotCorrectDestinationAddressForm",
    component: () => import("/app/src/layers/exchanger/pages/order/NotCorrectDestinationAddressForm.vue").then(m => m.default || m)
  },
  {
    name: "our-vision___en",
    path: "/our-vision",
    component: () => import("/app/src/layers/pages_and_theme/pages/our-vision.vue").then(m => m.default || m)
  },
  {
    name: "our-vision___ru",
    path: "/ru/our-vision",
    component: () => import("/app/src/layers/pages_and_theme/pages/our-vision.vue").then(m => m.default || m)
  },
  {
    name: "popular-exchanges___en",
    path: "/popular-exchanges",
    component: () => import("/app/src/layers/exchanger/pages/popular-exchanges/index.vue").then(m => m.default || m)
  },
  {
    name: "popular-exchanges___ru",
    path: "/ru/popular-exchanges",
    component: () => import("/app/src/layers/exchanger/pages/popular-exchanges/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-affiliate-program___en",
    path: "/profile/affiliate-program",
    meta: affiliate_45programKrH9eG0vZ1Meta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/affiliate-program.vue").then(m => m.default || m)
  },
  {
    name: "profile-affiliate-program___ru",
    path: "/ru/profile/affiliate-program",
    meta: affiliate_45programKrH9eG0vZ1Meta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/affiliate-program.vue").then(m => m.default || m)
  },
  {
    name: "profile-info___en",
    path: "/profile/info",
    meta: infoVkhXKCfC7CMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-info___ru",
    path: "/ru/profile/info",
    meta: infoVkhXKCfC7CMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-keys___en",
    path: "/profile/keys",
    meta: keys1qS3yXmXdaMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/keys.vue").then(m => m.default || m)
  },
  {
    name: "profile-keys___ru",
    path: "/ru/profile/keys",
    meta: keys1qS3yXmXdaMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/keys.vue").then(m => m.default || m)
  },
  {
    name: "profile-wallet___en",
    path: "/profile/wallet",
    meta: walletj4Sj4zIMFUMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/wallet.vue").then(m => m.default || m)
  },
  {
    name: "profile-wallet___ru",
    path: "/ru/profile/wallet",
    meta: walletj4Sj4zIMFUMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/wallet.vue").then(m => m.default || m)
  },
  {
    name: "profile-withdraw___en",
    path: "/profile/withdraw",
    meta: withdraw5GEox3h2EvMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "profile-withdraw___ru",
    path: "/ru/profile/withdraw",
    meta: withdraw5GEox3h2EvMeta || {},
    component: () => import("/app/src/layers/exchanger/pages/profile/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "roadmap___en",
    path: "/roadmap",
    component: () => import("/app/src/layers/blog/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: "roadmap___ru",
    path: "/ru/roadmap",
    component: () => import("/app/src/layers/blog/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/app/src/layers/pages_and_theme/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___ru",
    path: "/ru/support",
    component: () => import("/app/src/layers/pages_and_theme/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "testpage2___en",
    path: "/testpage2",
    component: () => import("/app/src/layers/pages_and_theme/pages/testpage2.vue").then(m => m.default || m)
  },
  {
    name: "testpage2___ru",
    path: "/ru/testpage2",
    component: () => import("/app/src/layers/pages_and_theme/pages/testpage2.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ru-RU-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]