import { useI18n } from "vue-i18n"

export function useIndexPageBlocks() {
  const { t } = useI18n()

  const whyBlocks = [
    {
      icon: "shield",
      title: "main_page_why_block_1_title",
      content: "main_page_why_block_1_content",
    },
    {
      icon: "cup",
      title: "main_page_why_block_3_title",
      content: "main_page_why_block_3_content",
    },
    {
      icon: "cycled_check",
      title: "main_page_why_block_5_title",
      content: "main_page_why_block_5_content",
    },
    {
      icon: "lightning",
      title: "main_page_why_block_2_title",
      content: "main_page_why_block_2_content",
    },
    {
      icon: "cycle",
      title: "main_page_why_block_4_title",
      content: "main_page_why_block_4_content",
    },

    {
      icon: "no_kyc",
      title: "main_page_why_block_6_title",
      content: "main_page_why_block_6_content",
    },
  ]
  const featuresBlocks = [
    {
      icon: "shield_with_man_blue",
      title: "main_page_features_block_1_title",
      content: "main_page_features_1_content",
    },
    {
      icon: "finger_tap",
      title: "main_page_features_block_2_title",
      content: "main_page_features_2_content",
    },
    {
      icon: "scroll_content",
      title: "main_page_features_block_3_title",
      content: "main_page_features_3_content",
    },
    {
      icon: "chart",
      title: "main_page_features_block_4_title",
      content: "main_page_features_4_content",
    },
    {
      icon: "laptop_with_phone_blue",
      title: "main_page_features_block_5_title",
      content: "main_page_features_5_content",
    },
    {
      icon: "operator",
      title: "main_page_features_block_6_title",
      content: "main_page_features_6_content",
    },
  ]
  const howToBlock = [
    {
      icon: "index_how_to_1",
      title: t("main_page_s2_b1_title"),
      content: t("main_page_s2_b1_content"),
    },
    {
      icon: "index_how_to_2",
      title: t("main_page_s2_b2_title"),
      content: t("main_page_s2_b2_content"),
    },
    {
      icon: "index_how_to_3",
      title: t("main_page_s2_b3_title"),
      content: t("main_page_s2_b3_content"),
    },
    {
      icon: "index_how_to_4",
      title: t("main_page_s2_b4_title"),
      content: t("main_page_s2_b4_content"),
    },
  ]

  return {
    whyBlocks,
    featuresBlocks,
    howToBlock,
  }
}
