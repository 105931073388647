
// @ts-nocheck
import locale__app_src_layers_pages_and_theme_common_i18n_lang_en_US_ts from "../src/layers/pages_and_theme/common/i18n/lang/en-US.ts?hash=65d319af&locale=en";
import locale__app_src_layers_pages_and_theme_common_i18n_lang_ru_RU_ts from "../src/layers/pages_and_theme/common/i18n/lang/ru-RU.ts?hash=4bc3c52c&locale=ru";


export const localeCodes =  [
  "en",
  "ru"
]

export const localeLoaders = {
  "en": [{ key: "../src/layers/pages_and_theme/common/i18n/lang/en-US.ts", load: () => Promise.resolve(locale__app_src_layers_pages_and_theme_common_i18n_lang_en_US_ts), cache: true }],
  "ru": [{ key: "../src/layers/pages_and_theme/common/i18n/lang/ru-RU.ts", load: () => Promise.resolve(locale__app_src_layers_pages_and_theme_common_i18n_lang_ru_RU_ts), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": true,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "ogp": "en_US",
      "name": "English",
      "title": "English (United States)",
      "language": "en",
      "files": [
        "/app/src/layers/pages_and_theme/common/i18n/lang/en-US.ts"
      ]
    },
    {
      "code": "ru",
      "ogp": "ru_RU",
      "name": "Русский",
      "title": "Russian (Russia)",
      "language": "ru-RU",
      "files": [
        "/app/src/layers/pages_and_theme/common/i18n/lang/ru-RU.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "layers/pages_and_theme/common/i18n/lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "ogp": "en_US",
    "name": "English",
    "title": "English (United States)",
    "language": "en",
    "files": [
      {
        "path": "/app/src/layers/pages_and_theme/common/i18n/lang/en-US.ts",
        "cache": true
      }
    ]
  },
  {
    "code": "ru",
    "ogp": "ru_RU",
    "name": "Русский",
    "title": "Russian (Russia)",
    "language": "ru-RU",
    "files": [
      {
        "path": "/app/src/layers/pages_and_theme/common/i18n/lang/ru-RU.ts",
        "cache": true
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
