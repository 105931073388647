import { defineNuxtPlugin } from '#app/nuxt'
import { LazyExchangeAddress, LazyExchangeAlertPopup, LazyExchangeAlertPopupMinMaxContent, LazyExchangeAlertPopupStandart, LazyExchangeAnchorsBlockWithMenu, LazyExchangeBadgeNetwork, LazyExchangeButtonFixedFloat, LazyExchangeCoinSelector, LazyExchangeCoinSelectorListItem, LazyExchangeCoins, LazyExchangePairPageHeader, LazyExchangeFieldMemo, LazyExchangeForm, LazyExchangeNotExistPair, LazyExchangeOrderTimeRemaining, LazyExchangeOtherPairs, LazyExchangePairNotSupportedPopup, LazyExchangeSectionWithForm, LazyExchangeTooltip, LazyExchangeTrusted, LazyExchangeWhatYouNeedToKnow, LazyApplicationLogo, LazyBlueIconContainer, LazyPageHeader, LazyExchangeHowToSwap, LazyExchangePageExchangePair, LazyFooter, LazyFooterBottom, LazyPagesIndexFAQ, LazyPagesIndexSectionBlocks, LazyPagesIndexReviewProvider, LazyPagesIndexReviewRate, LazyPagesIndexReview, LazyPagesIndexReviewSection, LazyPagesIndexReviewTestReviews, LazyPagesUseIndexPage, LazyTrustItemLinkPic, LazyTrustLinks, LazyBlogMain, LazyBlogSlug, LazyBlogPostCard, LazyBlogPosts, LazyDocsSlug, LazyLayoutsLayoutAside, LazyLayoutsLayoutWrapper } from '#components'
const lazyGlobalComponents = [
  ["ExchangeAddress", LazyExchangeAddress],
["ExchangeAlertPopup", LazyExchangeAlertPopup],
["ExchangeAlertPopupMinMaxContent", LazyExchangeAlertPopupMinMaxContent],
["ExchangeAlertPopupStandart", LazyExchangeAlertPopupStandart],
["ExchangeAnchorsBlockWithMenu", LazyExchangeAnchorsBlockWithMenu],
["ExchangeBadgeNetwork", LazyExchangeBadgeNetwork],
["ExchangeButtonFixedFloat", LazyExchangeButtonFixedFloat],
["ExchangeCoinSelector", LazyExchangeCoinSelector],
["ExchangeCoinSelectorListItem", LazyExchangeCoinSelectorListItem],
["ExchangeCoins", LazyExchangeCoins],
["ExchangePairPageHeader", LazyExchangePairPageHeader],
["ExchangeFieldMemo", LazyExchangeFieldMemo],
["ExchangeForm", LazyExchangeForm],
["ExchangeNotExistPair", LazyExchangeNotExistPair],
["ExchangeOrderTimeRemaining", LazyExchangeOrderTimeRemaining],
["ExchangeOtherPairs", LazyExchangeOtherPairs],
["ExchangePairNotSupportedPopup", LazyExchangePairNotSupportedPopup],
["ExchangeSectionWithForm", LazyExchangeSectionWithForm],
["ExchangeTooltip", LazyExchangeTooltip],
["ExchangeTrusted", LazyExchangeTrusted],
["ExchangeWhatYouNeedToKnow", LazyExchangeWhatYouNeedToKnow],
["ApplicationLogo", LazyApplicationLogo],
["BlueIconContainer", LazyBlueIconContainer],
["PageHeader", LazyPageHeader],
["ExchangeHowToSwap", LazyExchangeHowToSwap],
["ExchangePageExchangePair", LazyExchangePageExchangePair],
["Footer", LazyFooter],
["FooterBottom", LazyFooterBottom],
["PagesIndexFAQ", LazyPagesIndexFAQ],
["PagesIndexSectionBlocks", LazyPagesIndexSectionBlocks],
["PagesIndexReviewProvider", LazyPagesIndexReviewProvider],
["PagesIndexReviewRate", LazyPagesIndexReviewRate],
["PagesIndexReview", LazyPagesIndexReview],
["PagesIndexReviewSection", LazyPagesIndexReviewSection],
["PagesIndexReviewTestReviews", LazyPagesIndexReviewTestReviews],
["PagesUseIndexPage", LazyPagesUseIndexPage],
["TrustItemLinkPic", LazyTrustItemLinkPic],
["TrustLinks", LazyTrustLinks],
["BlogMain", LazyBlogMain],
["BlogSlug", LazyBlogSlug],
["BlogPostCard", LazyBlogPostCard],
["BlogPosts", LazyBlogPosts],
["DocsSlug", LazyDocsSlug],
["LayoutsLayoutAside", LazyLayoutsLayoutAside],
["LayoutsLayoutWrapper", LazyLayoutsLayoutWrapper],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
