import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import global_45auth from "/app/src/calcStore/middleware/auth.ts";
import meta from "/app/src/calcStore/middleware/meta.global.ts";
import ref from "/app/src/calcStore/middleware/ref.global.ts";
import canonical from "/app/src/calcStore/middleware/canonicalLinks.global.ts";
import trailingSlash from "/app/src/calcStore/middleware/trailingSlash.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  global_45auth,
  meta,
  ref,
  canonical,
  trailingSlash,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "coins-query": () => import("/app/src/calcStore/middleware/coinsQuery.ts"),
  "lk-redirect": () => import("/app/src/calcStore/middleware/lk-redirect.ts"),
  "fetch-translate-for-exchange-page": () => import("/app/src/calcStore/middleware/i18FetchTranslateForExchangePage.ts")
}