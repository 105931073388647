export interface IReview {
  username: string
  message: string
  date: string
  url: string
}

export interface IReviewNew {
  provider: string
  fullName: string
  title: string
  rate: number
  text: string
  avatarUrl: string
  createDate: string
  url: string
}

export const reviews = [
  /* START */
  {
    provider: "trustpilot",
    fullName: "Tiago G",
    title: "Simple, no unnecessary manipulations",
    rate: 5,
    text: "I first tried to make an exchange on swapgate about a week ago. I won’t say that the exchanger is the best of the best, but it’s just simple, without any unnecessary complications. So I easily did what I wanted and that’s it.",
    avatarUrl: "https://user-images.trustpilot.com/643c4baed37d1b00122626a9/73x73.png",
    createDate: "05/10/2024",
    url: "https://www.trustpilot.com/reviews/66462e49adc8330a53767022",
  },
  {
    provider: "trustpilot",
    fullName: "Ulvi",
    title: "kinda fast swap",
    rate: 5,
    text: "kinda fast swap, good from my side",
    avatarUrl: undefined,
    createDate: "05/21/2024",
    url: "https://www.trustpilot.com/reviews/664c68d616ce17148338ef45",
  },

  {
    provider: "trustpilot",
    fullName: "Марина Лепушенко",
    title: "The most simple and easy to use",
    rate: 5,
    text: "The most simple and easy to use!\n" + "Only 5 stars!",
    avatarUrl: "https://user-images.trustpilot.com/65ca7c9388f04d001260387a/73x73.png",
    createDate: "05/20/2024",
    url: "https://www.trustpilot.com/reviews/664ae966296296132c21dbc1",
  },
  {
    provider: "trustpilot",
    fullName: "M T",
    title: "The most simple and easy to use",
    rate: 5,
    text: "This is the best service of its kind",
    avatarUrl: undefined,
    createDate: "02/05/2024",
    url: "https://www.trustpilot.com/reviews/664473bdd3f8fb164ec75173",
  },

  {
    provider: "wot",
    fullName: "pauz85375",
    title: "site legit, tried swap there",
    rate: 5,
    text: "site legit, tried swap there",
    avatarUrl: undefined,
    createDate: "05/21/2024",
    url: "https://www.mywot.com/ru/scorecard/swapgate.io",
  },
  /* END */
]
